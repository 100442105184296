import React from 'react';

function DropDownSvg() {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.2222 10.9999C7.2222 11.859 6.52575 12.5554 5.66664 12.5554C4.80753 12.5554 4.11108 11.859 4.11108 10.9999C4.11108 10.1408 4.80753 9.44434 5.66664 9.44434C6.52575 9.44434 7.2222 10.1408 7.2222 10.9999ZM12.5555 10.9999C12.5555 11.859 11.8591 12.5554 11 12.5554C10.1409 12.5554 9.44442 11.859 9.44442 10.9999C9.44442 10.1408 10.1409 9.44434 11 9.44434C11.8591 9.44434 12.5555 10.1408 12.5555 10.9999ZM16.3333 12.5554C17.1924 12.5554 17.8889 11.859 17.8889 10.9999C17.8889 10.1408 17.1924 9.44434 16.3333 9.44434C15.4742 9.44434 14.7778 10.1408 14.7778 10.9999C14.7778 11.859 15.4742 12.5554 16.3333 12.5554Z"
        fill="#333333"
      />
    </svg>
  );
}

export default DropDownSvg;
