import React from 'react';

function ChatBubbleSvg() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5232_56317)">
        <path
          d="M8.29301 1.006C8.42968 1.01167 8.49568 1.17333 8.41301 1.28233C7.81921 2.06376 7.49844 3.01856 7.50001 4C7.50001 6.48533 9.51468 8.5 12 8.5C12.9815 8.50147 13.9363 8.18058 14.7177 7.58667C14.8267 7.50433 14.9883 7.57067 14.994 7.707C14.998 7.80433 15 7.902 15 8C15 11.866 11.866 15 8.00001 15C6.74812 15.002 5.51882 14.6667 4.44134 14.0293C3.65601 14.3217 2.76201 14.611 1.88101 14.8193C1.78227 14.8443 1.6788 14.8436 1.58038 14.8174C1.48196 14.7912 1.39184 14.7404 1.31853 14.6697C1.24523 14.599 1.19116 14.5108 1.16144 14.4134C1.13172 14.316 1.12733 14.2126 1.14868 14.113C1.33634 13.1933 1.62268 12.273 1.92534 11.481C1.31713 10.4218 0.998025 9.22138 1.00001 8C1.00001 4.134 4.13401 1 8.00001 1C8.09801 1 8.19567 1.002 8.29301 1.006Z"
          fill="#E7E5E4"
        />
        <path
          d="M15 4C15 4.79565 14.6839 5.55871 14.1213 6.12132C13.5587 6.68393 12.7956 7 12 7C11.2044 7 10.4413 6.68393 9.87868 6.12132C9.31607 5.55871 9 4.79565 9 4C9 3.20435 9.31607 2.44129 9.87868 1.87868C10.4413 1.31607 11.2044 1 12 1C12.7956 1 13.5587 1.31607 14.1213 1.87868C14.6839 2.44129 15 3.20435 15 4Z"
          fill="white"
        />
        <path
          d="M8 1C4.134 1 1 4.134 1 8C1 9.26733 1.33667 10.4557 1.92567 11.481C1.62233 12.2733 1.33633 13.1933 1.14867 14.113C1.12725 14.2126 1.13159 14.316 1.16129 14.4135C1.19098 14.5109 1.24503 14.5992 1.31835 14.67C1.39166 14.7407 1.48181 14.7916 1.58026 14.8178C1.67871 14.844 1.78222 14.8446 1.881 14.8197C2.762 14.611 3.656 14.3217 4.44133 14.0293C5.51881 14.6667 6.74812 15.002 8 15C11.866 15 15 11.866 15 8C15 7.88833 14.9973 7.777 14.9923 7.66667"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15 4C15 4.79565 14.6839 5.55871 14.1213 6.12132C13.5587 6.68393 12.7956 7 12 7C11.2044 7 10.4413 6.68393 9.87868 6.12132C9.31607 5.55871 9 4.79565 9 4C9 3.20435 9.31607 2.44129 9.87868 1.87868C10.4413 1.31607 11.2044 1 12 1C12.7956 1 13.5587 1.31607 14.1213 1.87868C14.6839 2.44129 15 3.20435 15 4Z"
          stroke="black"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5232_56317">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ChatBubbleSvg;
