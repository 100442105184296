import React from 'react';

function LoginSvg() {
  return (
<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_5728_13979" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="1" width="25" height="26">
<path fillRule="evenodd" clipRule="evenodd" d="M8.4 1.86666C7.88453 1.86666 7.46667 2.28453 7.46667 2.8C7.46667 3.31546 7.88453 3.73333 8.4 3.73333H22.4V24.2667H8.4C7.88453 24.2667 7.46667 24.6845 7.46667 25.2C7.46667 25.7155 7.88453 26.1333 8.4 26.1333H22.4C23.4309 26.1333 24.2667 25.2976 24.2667 24.2667V3.73333C24.2667 2.7024 23.4309 1.86666 22.4 1.86666H8.4ZM12.3266 9.14003C11.9621 8.77554 11.3712 8.77554 11.0067 9.14003C10.6422 9.50452 10.6422 10.0955 11.0067 10.46L13.6134 13.0667H0.933333C0.417868 13.0667 0 13.4845 0 14C0 14.5155 0.417868 14.9333 0.933333 14.9333H13.6134L11.0067 17.54C10.6422 17.9045 10.6422 18.4955 11.0067 18.86C11.3712 19.2245 11.9621 19.2245 12.3266 18.86L16.5266 14.66C16.8911 14.2955 16.8911 13.7045 16.5266 13.34L12.3266 9.14003Z" fill="#262564"/>
</mask>
<g mask="url(#mask0_5728_13979)">
<rect width="28" height="28" fill="#262564"/>
</g>
</svg>
  );
}

export default LoginSvg;
