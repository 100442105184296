import React from 'react';

function ViewNodata() {
  return (
    <div className="flex p-4 justify-center items-center gap-4">
      <div className="grow shrink basis-0 self-stretch text-center text-stone-500 text-sm font-normal font-goth leading-tight">
        No data available
      </div>
    </div>
  );
}

export default ViewNodata;
