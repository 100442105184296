import React from 'react';

function SortDownSvg() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="6" viewBox="0 0 8 6" fill="none">
      <path d="M4 6L0.535899 -6.52533e-07L7.4641 -4.68497e-08L4 6Z" fill="#9CA3AF"/>
    </svg>
  );
}

export default SortDownSvg;
