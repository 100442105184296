import React from 'react';

function EyeCloseSvg() {
  return (
   <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_5829_16654" style={{maskType: "alpha"}} maskUnits="userSpaceOnUse" x="0" y="8" width="21" height="10">
<path fillRule="evenodd" clipRule="evenodd" d="M20.1867 8.10135C20.4989 8.29649 20.5938 8.70779 20.3987 9.02001C19.8133 9.95666 19.1176 10.8087 18.327 11.5508L19.9283 13.1522C20.1887 13.4125 20.1887 13.8347 19.9283 14.095C19.668 14.3554 19.2459 14.3554 18.9855 14.095L17.3015 12.411C16.24 13.2039 15.0452 13.8162 13.745 14.2015L14.344 16.437C14.4393 16.7927 14.2282 17.1582 13.8726 17.2535C13.5169 17.3488 13.1514 17.1377 13.0561 16.7821L12.4456 14.5035C11.8166 14.6108 11.1672 14.6667 10.5 14.6667C9.83286 14.6667 9.18342 14.6108 8.55442 14.5035L7.94397 16.7821C7.84869 17.1377 7.48314 17.3488 7.12749 17.2535C6.77184 17.1582 6.56078 16.7927 6.65606 16.437L7.25497 14.2015C5.95479 13.8162 4.76 13.2039 3.69849 12.411L2.01447 14.095C1.75412 14.3553 1.33201 14.3553 1.07166 14.095C0.811309 13.8346 0.811309 13.4125 1.07166 13.1522L2.67304 11.5508C1.88236 10.8087 1.18675 9.95665 0.601348 9.02001C0.406207 8.70779 0.501122 8.29649 0.813346 8.10135C1.12557 7.90621 1.53687 8.00112 1.73201 8.31335C2.37133 9.33625 3.15194 10.2408 4.04973 10.9904C4.06415 11.0014 4.07822 11.0131 4.09191 11.0254C5.84526 12.4738 8.04048 13.3333 10.5 13.3333C14.2438 13.3333 17.3752 11.3418 19.268 8.31335C19.4632 8.00112 19.8745 7.90621 20.1867 8.10135Z" fill="#0891B2"/>
</mask>
<g mask="url(#mask0_5829_16654)">
<rect x="0.5" width="20" height="20" fill="#262564"/>
</g>
</svg>
  );
}

export default EyeCloseSvg;
