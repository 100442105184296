import React from 'react';

function EyeOpenSvg() {
  return (
   <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_5829_41616" style={{maskType: "alpha"}} maskUnits="userSpaceOnUse" x="0" y="4" width="21" height="12">
<path fillRule="evenodd" clipRule="evenodd" d="M10.5 14.6667C6.9038 14.6667 3.87269 12.8291 1.96163 10C3.87269 7.17088 6.9038 5.33333 10.5 5.33333C14.0962 5.33333 17.1273 7.17088 19.0384 10C17.1273 12.8291 14.0962 14.6667 10.5 14.6667ZM10.5 4C6.24381 4 2.70852 6.27517 0.601334 9.64669C0.466222 9.86287 0.466222 10.1372 0.601335 10.3534C2.70852 13.7248 6.24381 16 10.5 16C14.7562 16 18.2915 13.7248 20.3987 10.3533C20.5338 10.1372 20.5338 9.86287 20.3987 9.64668C18.2915 6.27517 14.7562 4 10.5 4ZM10.5 12.6667C11.9728 12.6667 13.1667 11.4728 13.1667 10C13.1667 8.52724 11.9728 7.33333 10.5 7.33333C9.02724 7.33333 7.83333 8.52724 7.83333 10C7.83333 11.4728 9.02724 12.6667 10.5 12.6667Z" fill="#0891B2"/>
</mask>
<g mask="url(#mask0_5829_41616)">
<rect x="0.5" width="20" height="20" fill="#262564"/>
</g>
</svg>
  );
}

export default EyeOpenSvg;
