import React from 'react';

function ThumbSvg({ color }) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.12512 2.04304C6.77156 3.22667 6.06711 5.13918 5.4501 6.4908L5.28516 16.3691C7.19308 16.6585 10.4901 17.061 13.7359 16.9922C14.7332 16.9712 15.6541 16.4149 15.9996 15.4791C16.9893 12.7976 17.0565 9.34525 16.9748 7.19296C16.9309 6.03224 15.958 5.16399 14.7981 5.22432C13.7099 5.28121 12.4942 5.37399 11.2544 5.49045C11.2812 4.76118 11.2838 3.93531 11.2258 3.12396C11.1651 2.27786 10.7264 1.47681 9.91084 1.24314C9.45763 1.11332 8.97387 1.04269 8.5554 1.00527C7.89104 0.945324 7.31641 1.4035 7.1255 2.04304H7.12512Z"
        fill="white"
      />
      <path
        d="M1.02441 15.6807C1.03968 16.242 1.37033 16.746 1.919 16.8632C2.27141 16.9384 2.72272 16.9999 3.25573 16.9999C3.78875 16.9999 4.24005 16.9388 4.59208 16.8632C5.14113 16.746 5.47217 16.2416 5.48706 15.6807C5.51264 14.7277 5.54662 13.1149 5.54662 11.2727C5.54662 9.4304 5.51264 7.81762 5.48706 6.86499C5.47178 6.30372 5.14113 5.79973 4.59247 5.68251C4.15293 5.59008 3.70488 5.54413 3.25573 5.54544C2.72272 5.54544 2.27141 5.60729 1.91938 5.68251C1.37033 5.79973 1.0393 6.3041 1.02441 6.86499C0.998825 7.81762 0.964844 9.4304 0.964844 11.2727C0.964844 13.1149 0.998825 14.7277 1.02441 15.6807Z"
        fill="#E7E5E4"
      />
      <path
        d="M1.02441 15.6807C1.03968 16.242 1.37033 16.746 1.919 16.8632C2.27141 16.9384 2.72272 16.9999 3.25573 16.9999C3.78875 16.9999 4.24005 16.9388 4.59208 16.8632C5.14113 16.746 5.47217 16.2416 5.48706 15.6807C5.51264 14.7277 5.54662 13.1149 5.54662 11.2727C5.54662 9.4304 5.51264 7.81762 5.48706 6.86499C5.47178 6.30372 5.14113 5.79973 4.59247 5.68251C4.15293 5.59008 3.70488 5.54413 3.25573 5.54544C2.72272 5.54544 2.27141 5.60729 1.91938 5.68251C1.37033 5.79973 1.0393 6.3041 1.02441 6.86499C0.998825 7.81762 0.964844 9.4304 0.964844 11.2727C0.964844 13.1149 0.998825 14.7277 1.02441 15.6807Z"
        stroke="black"
        strokeWidth="1.14544"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.4501 6.49042C6.06711 5.13918 6.77156 3.22667 7.12512 2.04304C7.31603 1.4035 7.89104 0.945324 8.55578 1.00527C8.97387 1.04269 9.45763 1.11332 9.91122 1.24314C10.7264 1.47681 11.1651 2.27786 11.2258 3.12396C11.2838 3.93531 11.2816 4.76156 11.2544 5.49045C12.4942 5.37399 13.7103 5.28121 14.7977 5.2247C15.9584 5.16399 16.9309 6.03224 16.9748 7.19296C17.0565 9.34525 16.9893 12.798 15.9996 15.4791C15.6541 16.4145 14.7332 16.9712 13.7359 16.9922C10.4901 17.061 7.19308 16.6589 5.28516 16.3695"
        stroke="black"
        strokeWidth="1.14544"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={color ? color : 'white'}
      />
      <path
        d="M3.25781 14.7089V13.9453"
        stroke="black"
        strokeWidth="1.14544"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ThumbSvg;
