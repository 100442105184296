import React from 'react';

function SortSvg() {
  return (
    <svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 0L7.4641 6H0.535898L4 0Z" fill="#9CA3AF"/>
      <path d="M4 16L0.535899 10L7.4641 10L4 16Z" fill="#9CA3AF"/>
    </svg>
  );
}

export default SortSvg;
