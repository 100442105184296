import React from 'react';

function SortUpSvg() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="6" viewBox="0 0 8 6" fill="none">
      <path d="M4 0L7.4641 6H0.535898L4 0Z" fill="#9CA3AF"/>
    </svg>
  );
}

export default SortUpSvg;
